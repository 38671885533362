import { gql } from '@apollo/client'

export const CREATE_ORDER = gql`
    mutation CREATE_ORDER($items: [OrderItemInput!]!, $store: String!) {
        createOrder(items: $items, store: $store) {
            id
            items {
                id
                amount
            }
            userPhoneNumber
        }
    }
`
