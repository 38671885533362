import React, { FunctionComponent } from 'react'
import Category from './Category'

import './index.scss'
import { useSwipeable } from 'react-swipeable'

const Categories: FunctionComponent = () => {
  return (
    <div className={'categories'}>
      <Category />
      <Category />
      <Category />
      <Category />
      <Category />
    </div>
  )
}

export default Categories
