import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client'
import { State } from '../../reducers'
import history from '../../history'
import { onError } from '@apollo/client/link/error'
import React, { FunctionComponent } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { RouterParams } from '../../types/router'
import { connect } from 'react-redux';

type Args = {
  company: string | null
}

const ApolloWrapper: FunctionComponent<Args> = ({ children, company }) => {
  console.log(process.env.REACT_APP_API_URL)
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL, credentials: 'include' })

  const logoutLink = onError(({ networkError }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      history.push(`/${company}/auth`)
      localStorage.signedIn = false
    }
  })

  const routeLink = onError(({ graphQLErrors, networkError }) => {
    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].extensions?.code === 'ROUTE_ERROR'
    ) {
      history.push(`/404`)
    }
  })

  const companyMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        company,
      },
    })
    return forward(operation)
  })

  const client = new ApolloClient({
    link: ApolloLink.from([companyMiddleware, routeLink, logoutLink, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            products: {
              keyArgs: ['search'],
              merge(existing = [], incoming) {
                return [...existing, ...incoming]
              },
            },
          },
        },
      },
    }),
    credentials: 'include',
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default connect(({ company }: State) => ({ company }), {})(
  ApolloWrapper
)
