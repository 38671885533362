import React, { FunctionComponent, useState } from 'react'
import { Shop } from '../../reducers'
import Modal from '../Modal'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

import './index.scss'
import { useHistory } from 'react-router'
import { setSelectedShop } from '../../actions/index';
import { connect } from 'react-redux'
import ShopCard from '../ShopCard'
import { useRouteMatch } from 'react-router-dom'
import { RouterParams } from '../../types/router'

type Args = {
  shop: Shop | null
  setSelectedShop: typeof setSelectedShop
}

const ShopModal: FunctionComponent<Args> = ({ shop, setSelectedShop }) => {
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()
  const { params } = useRouteMatch<RouterParams>()

  const handleShopSelect = () => {
    if (shop) {
      setSelectedShop(shop.id)
      setIsOpen(false)
    }
  }

  const handleStoresListClick = () => {
    history.push(`/${params.company}/shopList`)
    setIsOpen(false)
  }

  if (!shop) return null
  return (
    <Modal isActive={isOpen} withoutWindow>
      <div className="shop-modal-wrapper">
        <div className="shop-modal-info">
          <header className="shop-modal-info-header">
            <h3>Choose a store that suits you</h3>
            <h4>This store is near you</h4>
          </header>
          <div className="shop-modal-info-padded">
            <ShopCard shop={shop}/>
          </div>
          <div className="shop-modal-info-actions">
            <button onClick={handleShopSelect}>It fits me</button>
            <button onClick={handleStoresListClick}>Stores List</button>
          </div>
        </div>
        <button
          className="shop-modal-close-button"
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon fill="gray" />
        </button>
      </div>
    </Modal>
  )
}

export default connect(null, { setSelectedShop })(ShopModal)
