import React, { FunctionComponent, useEffect, useState } from 'react'
import Modal from '../Modal'

import './index.scss'

import { ReactComponent as SubCategoriesIcon } from './assets/icon.svg'
import CategoryOption from './components/CategoryOption'
import { connect } from 'react-redux'
import { State } from '../../reducers'
import { setSubCategoriesFilter } from '../../actions'

type Filter = {
  [key: number]: boolean
}

type Args = {
  subCategoriesFilter: Filter
  setSubCategoriesFilter: (val: Filter) => void
}

const data = [
  { id: 1, title: 'Test1' },
  { id: 2, title: 'Test1' },
  { id: 3, title: 'Test1' },
  { id: 4, title: 'Test1' },
  { id: 5, title: 'Test1' },
  { id: 6, title: 'Test1' },
  { id: 7, title: 'Test1' },
  { id: 8, title: 'Test1' },
]

const SubCategories: FunctionComponent<Args> = ({
  subCategoriesFilter,
  setSubCategoriesFilter,
}) => {
  const [localFilter, setLocalFilter] = useState(subCategoriesFilter)
  const [isOpen, setOpen] = useState(false)
  const filterLength = Object.keys(subCategoriesFilter).length

  useEffect(() => setLocalFilter(subCategoriesFilter), [subCategoriesFilter])
  const handleOptionClick = (id: number) => {
    console.log(id)
    const localFilterCopy = { ...localFilter }
    if (localFilter[id]) delete localFilterCopy[id]
    else localFilterCopy[id] = true
    setLocalFilter(localFilterCopy)
  }

  const handleFilterApply = () => {
    setSubCategoriesFilter(localFilter)
    setOpen(false)
  }

  const handleSubFilterClear = () => setSubCategoriesFilter({})

  return (
    <div>
      <Modal isActive={isOpen} withoutWindow={true}>
        <div className={'subCategories-modal'}>
          <button onClick={handleSubFilterClear}>Clear</button>
          <div className={'subCategories-selector'}>
            {data.map((item) => (
              <CategoryOption
                title={item.title}
                isActive={localFilter[item.id]}
                key={item.id}
                onClick={() => handleOptionClick(item.id)}
              />
            ))}
          </div>
          <div className={'subCategories-actions'}>
            <button onClick={() => setOpen(false)}>Cancel</button>
            <button className={'active'} onClick={handleFilterApply}>
              Add
            </button>
          </div>
        </div>
      </Modal>
      <button className={'subcategories-main'} onClick={() => setOpen(true)}>
        <p className={filterLength ? 'active' : ''}>
          {filterLength ? `Subcategories (${filterLength})` : 'Subcategories'}
        </p>
        <SubCategoriesIcon />
      </button>
    </div>
  )
}

export default connect(
  ({ subCategoriesFilter }: State) => ({ subCategoriesFilter }),
  { setSubCategoriesFilter }
)(SubCategories)
