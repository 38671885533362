import React, { FunctionComponent, useEffect, useRef } from 'react'
import './index.scss'
import { ReactComponent as MenuIcon } from './assets/menu.svg'
import { ReactComponent as Logo } from './assets/logo.svg'
import { ReactComponent as ShopIcon } from './assets/shop.svg'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import { connect } from 'react-redux'
import {
  setMenuOpen,
  setSearchValue,
  switchSearchActive,
  setSearchActive,
} from '../../actions'
import { State } from '../../reducers'
import Search from '../Search'
import {
  Link,
  RouteComponentProps,
  useRouteMatch,
  withRouter,
} from 'react-router-dom'
import { RouterParams } from '../../types/router'

type Args = {
  isSearchActive: boolean
  searchValue: string
  switchSearchActive: () => void
  setSearchActive: typeof setSearchActive
  isMenuOpen: boolean
  setMenuOpen: (val: boolean) => void
  setSearchValue: (val: string) => void
}

const Header: FunctionComponent<RouteComponentProps & Args> = ({
  switchSearchActive,
  isSearchActive,
  setSearchActive,
  setMenuOpen,
  isMenuOpen,
  history,
}) => {
  const { params } = useRouteMatch<RouterParams>()
  let unregisterHistoryListener = useRef<undefined | (() => void)>()
  const handleSearchClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    switchSearchActive()
  }
  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setMenuOpen(!isMenuOpen)
  }
  useEffect(() => {
    unregisterHistoryListener.current = history.listen(() => {
      setSearchActive(false)
    })
    return () =>
      unregisterHistoryListener.current && unregisterHistoryListener.current()
  }, [])
  return (
    <div className={'header-wrapper'}>
      <div className={'header-menu'}>
        <a href="#" onClick={handleMenuClick}>
          <MenuIcon />
        </a>
      </div>
      {!isSearchActive ? (
        <>
          <div className={'header-logo'}>
            <Link to={`/${params.company}`}>
              <Logo />
            </Link>
          </div>
          <div className={'header-actions'}>
            <div className={'actions-wrapper'}>
              <a href="#">
                <ShopIcon />
              </a>
              <a href="#" onClick={handleSearchClick}>
                <SearchIcon />
              </a>
            </div>
          </div>
        </>
      ) : (
        <Search />
      )}
    </div>
  )
}

export default connect(
  ({ isSearchActive, searchValue, isMenuOpen }: State) => ({
    isSearchActive,
    searchValue,
    isMenuOpen,
  }),
  { switchSearchActive, setSearchValue, setMenuOpen, setSearchActive }
)(withRouter(Header))
