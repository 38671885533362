import React, { FunctionComponent, useRef } from 'react'
import { Action } from '../../index'

import './index.scss'
import { Link, useRouteMatch } from 'react-router-dom'
import { getAllProducts_products } from '../../../../pages/Main/gql/types/getAllProducts'
import { RouterParams } from '../../../../types/router'

type Args = {
  item: getAllProducts_products
  actions: Action[]
  fields: (keyof getAllProducts_products)[]
  customHandlers?: {
    [name: string]: (val: any) => JSX.Element
  }
}

const Product: FunctionComponent<Args> = ({
  item,
  actions,
  fields,
  customHandlers,
}) => {
  const { params } = useRouteMatch<RouterParams>()
  const handleClick = (e: React.MouseEvent) => (func: () => void) => {
    e.preventDefault()
    e.stopPropagation()
    func()
  }

  const productRef = useRef<HTMLDivElement | null>(null)
  return (
    <Link to={`/${params.company}/product/${item.id}`}>
      <div className={'product'} ref={productRef}>
        <div className={'product-info-wrapper'}>
          <div className="product-image">
            <img src={ item.image?.data ? `data:image/jpeg;base64, ${item.image.data}` : 'https://storcpdkenticomedia.blob.core.windows.net/media/recipemanagementsystem/media/recipe-media-files/recipes/retail/desktopimages/rainbow-cake600x600_2.jpg?ext=.jpg'} alt="product" />
          </div>
          <div className={'product-fields'}>
            {fields.map((field, idx) => {
              if (customHandlers && customHandlers[field])
                return customHandlers[field](item[field])
              else
                return <span className={`product-${field}`} key={idx}>{item[field]}</span>
            })}
          </div>
        </div>
        <div className="product-actions">
          <button onClick={(e) => {
              // e.stopPropagation()
              handleClick(e)(() => actions[0].onClick(item, productRef.current))
            }}>{actions[0].icon}</button>
          <button
            onClick={(e) => {
              // e.stopPropagation()
              handleClick(e)(() => actions[1].onClick(item))
            }}
          >
            {actions[1].icon}
          </button>
        </div>
      </div>
    </Link>
  )
}

export default Product
