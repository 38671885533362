import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import history from './history'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import ApolloWrapper from './wrappers/ApolloWrapper'
import CompanyWatcher from './wrappers/CompanyWatcher'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloWrapper>
        <Router history={history}>
          <Switch>
            <Route path="/404">
              <h1>This store does not exist</h1>
            </Route>
            <Route path="/:company">
              <CompanyWatcher>
                <App />
              </CompanyWatcher>
            </Route>
          </Switch>
        </Router>
      </ApolloWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
