import { Action } from 'redux'
import { getAllProducts_products } from '../pages/Main/gql/types/getAllProducts'

export type BasketItem = getAllProducts_products & { amount: number }

export type BasketType = { [id: number]: BasketItem }

export type Shop = {
  id: string
  coordinates: string
  googleMapsUrl: string
  name: string | null
  formattedAddress: string | null
  distance: number | null
  weekdayText: string[] | null
}

export type State = {
  isSearchActive: boolean
  searchValue: string
  isMenuOpen: boolean
  subCategoriesFilter: { [key: number]: boolean }
  basket: BasketType
  selectedShop: string | null
  shopList: Shop[] | []
  selectedProduct: getAllProducts_products | null
  isProductCounterActive: Boolean
  company: string | null
}

type AnyPayload = {
  payload?: any
}

const initialState: State = {
  company: null,
  isSearchActive: false,
  searchValue: '',
  isMenuOpen: false,
  subCategoriesFilter: {},
  basket: {},
  shopList: [],
  selectedShop: null,
  isProductCounterActive: false,
  selectedProduct: null,
}

export const reducer = (state = initialState, action: Action & AnyPayload): State => {
  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.payload
      }
    case 'SWITCH_SEARCH_ACTIVE':
      return {
        ...state,
        isSearchActive: !state.isSearchActive,
      }
    case 'SET_SHOP_LIST':
      return {
        ...state,
        shopList: action.payload,
      }
    case 'SET_SELECTED_SHOP':
      localStorage.selectedShop = action.payload
      return {
        ...state,
        selectedShop: action.payload,
      }
    case 'SET_SEARCH_ACTIVE':
      return {
        ...state,
        isSearchActive: action.payload,
      }
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload as string,
      }
    case 'CLEAR_SEARCH_VALUE':
      return {
        ...state,
        searchValue: '',
      }
    case 'SET_MENU_OPEN':
      if (action.payload !== state.isMenuOpen) {
        return {
          ...state,
          isMenuOpen: action.payload,
        }
      } else return state
    case 'SET_SUB_CATEGORIES_FILTER':
      return {
        ...state,
        subCategoriesFilter: action.payload,
      }
    case 'SET_BASKET':
      localStorage.basket = JSON.stringify(action.payload)
      return {
        ...state,
        basket: action.payload,
      }
    case 'SWITCH_PRODUCT_COUNTER_ACTIVE':
      return {
        ...state,
        isProductCounterActive: action.payload,
      }
    case 'SET_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload,
      }
    default:
      return state
  }
}
