import React, { FunctionComponent, useState } from 'react'

import './index.scss'

type Item = {
  title: string
  subItems?: Item[]
}

type Args = {
  title: string
  items?: Item[]
}

const MenuItem: FunctionComponent<Args> = ({ title, items = [] }) => {
  const [isActive, setActive] = useState(false)
  return (
    <li className={'menu-item'}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setActive((state) => !state)
        }}
      >
        {title}
      </a>
      <ul className={isActive ? 'sub-menu active' : 'sub-menu'}>
        {items.map((item) => (
          <MenuItem title={item.title} items={item.subItems} />
        ))}
      </ul>
    </li>
  )
}

export default MenuItem
