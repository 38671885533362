import { gql } from "@apollo/client";

const GET_PRODUCT = gql`
    query getProduct($id: ID!) {
        getProduct(id: $id) {
            id
            name
            price
            description
            image {
                id
                data
            }
            allergens {
                id
                name
            }
            nutrions {
                id
                name
                amount
            }
            info {
                isVegan
            }
        }
    }
`

export default GET_PRODUCT