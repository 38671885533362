import React, { FunctionComponent } from 'react'
import MenuItem from './components/MenuItem'
import { connect } from 'react-redux'
import { State } from '../../reducers'

import './index.scss'

type Args = {
  isMenuOpen: boolean
}

const Menu: FunctionComponent<Args> = ({ isMenuOpen }) => {
  return (
    <div id="menu" className={isMenuOpen ? 'active' : ''}>
      <ul>
        <MenuItem
          title={'lorem1'}
          items={[{ title: 'Test', subItems: [{ title: 'Test 2' }] }]}
        />
        <MenuItem title={'lorem2'} />
        <MenuItem title={'lorem3'} />
        <MenuItem title={'lorem4'} />
        <MenuItem title={'lorem5'} />
      </ul>
    </div>
  )
}

export default connect(({ isMenuOpen }: State) => ({ isMenuOpen }), {})(Menu)
