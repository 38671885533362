import { Action } from 'redux'
import { getAllProducts_products } from '../pages/Main/gql/types/getAllProducts'
import { Shop } from '../reducers'
import { Data } from '../types'

type Payload<Dtype> = {
  payload: Dtype
}

type Filter = {
  [key: number]: boolean
}

export const switchSearchActive = (): Action => {
  return {
    type: 'SWITCH_SEARCH_ACTIVE',
  }
}

export const setCompany = (val: string): Action & Payload<string> => {
  return {
    type: 'SET_COMPANY',
    payload: val,
  }
}

export const setSearchActive = (val: boolean): Action & Payload<boolean> => {
  return {
    type: 'SET_SEARCH_ACTIVE',
    payload: val,
  }
}

export const setSelectedShop = (val: string): Action & Payload<string> => {
  return {
    type: 'SET_SELECTED_SHOP',
    payload: val,
  }
}

export const setShopList = (val: Shop[]): Action & Payload<Shop[]> => {
  return {
    type: 'SET_SHOP_LIST',
    payload: val,
  }
}

export const setSearchValue = (val: string): Action & Payload<string> => {
  return {
    type: 'SET_SEARCH_VALUE',
    payload: val,
  }
}

export const clearSearchValue = () => {
  return {
    type: 'CLEAR_SEARCH_VALUE',
  }
}

export const setMenuOpen = (val: boolean): Action & Payload<boolean> => {
  return {
    type: 'SET_MENU_OPEN',
    payload: val,
  }
}

export const setBasket = (newBasket: {
  [id: number]: getAllProducts_products
}) => {
  return {
    type: 'SET_BASKET',
    payload: newBasket,
  }
}

export const setSelectedProduct = (val: getAllProducts_products | null) => {
  return {
    type: 'SET_SELECTED_PRODUCT',
    payload: val,
  }
}

export const setProductCounterActive = (
  val: boolean
): Action & Payload<boolean> => {
  return {
    type: 'SWITCH_PRODUCT_COUNTER_ACTIVE',
    payload: val,
  }
}

export const setSubCategoriesFilter = (
  val: Filter
): Action & Payload<Filter> => {
  return {
    type: 'SET_SUB_CATEGORIES_FILTER',
    payload: val,
  }
}
