import { gql } from "@apollo/client";

export const PRODUCTS = gql`
    query getAllProducts($offset: Int, $limit: Int, $search: String){
        products(search: $search, offset: $offset, limit: $limit) {
            id
            name
            price
            image {
                id
                data
            }
            allergens {
                id
                name
            }
        }
    }
`