import { gql } from "@apollo/client";

export const INIT = gql`
    query INIT {
        shops {
            id
            googleMapsUrl
            coordinates
            name
            formattedAddress
            weekdayText
        }
    }
`;