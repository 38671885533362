import React, { FunctionComponent, useEffect } from 'react'

import './index.scss'

type Args = {
  withoutWindow: boolean
  isActive: boolean
}

const Modal: FunctionComponent<Args> = ({
  withoutWindow = false,
  isActive,
  children,
}) => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      if (isActive) root.classList.add('modal-open')
      else root.classList.remove('modal-open')
    }
  }, [isActive])
  return isActive ? (
    <div className={'modal-wrapper'}>
      {withoutWindow ? children : <h1>In progress</h1>}
    </div>
  ) : null
}

export default Modal
