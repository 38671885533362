import React, { FunctionComponent, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { setSelectedProduct } from '../../actions'

import './index.scss'
import { useQuery } from '@apollo/client'
import GET_PRODUCT from './gql'
import { getProduct, getProductVariables } from './gql/types/getProduct'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import withWrapper from '../../components/MainWrapper'

type Params = {
  id: string
}

type Args = {
  setSelectedProduct: typeof setSelectedProduct
}

const Product: FunctionComponent<RouteComponentProps<Params> & Args> = ({
  match,
  setSelectedProduct
}) => {
  const { loading, error, data } = useQuery<getProduct, getProductVariables>(
    GET_PRODUCT,
    {
      variables: {
        id: match.params.id,
      },
      fetchPolicy: 'cache-first',
    }
  )

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [])

  const product = data?.getProduct
  return (
    <div className={'product-page'}>
      <h2>{loading ? <Skeleton height={20} /> : product?.name}</h2>
      <div className={'product-availability'}>
        <p className={'availability-price'}>
          {' '}
          CHF {loading ? <Skeleton height={20} /> : product?.price}
        </p>
        <p className={'availability-days'}>(Mo-Sa)</p>
      </div>
      <div className={'product-image'}>
        {product?.image ? (
          <img
            src={
              product?.image?.data
                ? `data:image/jpeg;base64, ${product?.image?.data}`
                : 'https://cdn130.picsart.com/280756084007201.png?type=webp&to=min&r=640'
            }
            alt="product"
          />
        ) : (
          <Skeleton style={{ width: '100%', height: '300px' }} />
        )}
      </div>
      <div className="product-tags">
        <p>
          <span style={{ color: 'rgb(52,203,158)' }}>✔</span>Gluten
        </p>
        <p>
          <span style={{ color: 'rgb(52,203,158)' }}>✔</span>Gluten
        </p>
        <p>
          <span style={{ color: 'rgb(52,203,158)' }}>✔</span>Gluten
        </p>
      </div>
      <div className="product-description">
        <h3>Description</h3>
        <p>{loading ? <Skeleton height={60} /> : product?.description}</p>
      </div>
      <div className="product-info">
        <h3>More information</h3>
        {!loading
          ? product?.nutrions?.map(({ name, amount, id }) => (
              <div className="info-item" key={id}>
                <p className="info-item-title">{name}:</p>
                <p className="info-item-value">{amount}</p>
              </div>
            ))
          : [1, 2, 3, 4].map(() => <Skeleton height={20} />)}
      </div>
      <div className="allergens">
        <h3>Allergens</h3>
        <div className="allergens-list">
          {!loading
            ? product?.allergens?.map((item) => (
                <div className="allergens-list-item" key={item.id}>
                  <img
                    src={
                      'https://web-order-public.s3-eu-west-1.amazonaws.com/gluten.png'
                    }
                    alt="allergen"
                  />
                  <p className={'allergen-name'}>{item.name}</p>
                </div>
              ))
            : [1, 2, 3, 4].map(() => (
                <div
                  className="allergens-list-item"
                  style={{
                    display: 'block',
                    padding: '0 5px',
                    marginBottom: '15px',
                  }}
                >
                  <Skeleton height={90} style={{ width: '100%' }} />
                </div>
              ))}
        </div>
      </div>
      <div className="product-actions">
        <button onClick={() => window.history.back()}>Close</button>
        <button
          onClick={() => {
            console.log(product)
            product && setSelectedProduct(product)
          }}
        >
          Add
        </button>
      </div>
    </div>
  )
}

export default connect(null, {
  setSelectedProduct,
})(withWrapper<RouteComponentProps<Params> & Args>(Product))
