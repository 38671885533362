import React, { FunctionComponent, useEffect } from 'react'
import { setCompany } from '../../actions'
import { useRouteMatch } from 'react-router-dom'
import { RouterParams } from '../../types/router'
import { connect } from 'react-redux'
import { State } from '../../reducers'

type Args = {
  company: string | null
  setCompany: typeof setCompany
}

const CompanyWatcher: FunctionComponent<Args> = ({
  children,
  company: currentCompany,
  setCompany,
}) => {
  const {
    params: { company },
  } = useRouteMatch<RouterParams>()
  useEffect(() => {
      console.log(company, currentCompany)
      if (company && currentCompany !== company) {
          setCompany(company)
      }
  }, [company])
  
  if (!currentCompany) return null

  return <>{children}</>
}

export default connect(({ company }: State) => ({ company }), { setCompany })(
  CompanyWatcher
)
