import React, { FunctionComponent, ReactComponentElement } from 'react'
import Product from './components/Product'
import { Data } from '../../types'
import { getAllProducts_products } from '../../pages/Main/gql/types/getAllProducts'
import Skeleton from 'react-loading-skeleton'
import { BasketItem } from '../../reducers'

export type Action = {
  icon: JSX.Element
  onClick: (id: getAllProducts_products, element?: HTMLElement | null) => void
}

type Args = {
  loadingNextPage?: boolean,
  actions: Action[]
  fields: (keyof getAllProducts_products)[]
  data: getAllProducts_products[],
  customHandlers?: {
    [name: string]: (val: any, item?: BasketItem | getAllProducts_products) => JSX.Element
  }
}

const Products: FunctionComponent<Args> = ({
  data,
  fields,
  actions,
  customHandlers,
  loadingNextPage
}) => {
  console.log(loadingNextPage)
  return (
    <div className={'products'}>
      {data.map((item) => (
        <Product
          fields={fields}
          actions={actions}
          key={item.id}
          item={item}
          customHandlers={customHandlers}
        />
      ))}
      {
        loadingNextPage && <Skeleton count={20} height={90} style={{marginBottom: '10px', borderRadius: '7px'}}/>
      }
    </div>
  )
}

export default Products
