import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client'
import { connect } from 'react-redux'
import {
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Router } from 'react-router'
import history from './history'
import { INIT as INITType } from './gql/types/INIT'
import { useSwipeable } from 'react-swipeable'
import { setBasket, setMenuOpen, setShopList, setSelectedShop } from './actions'
import Index from './pages/Main'
import { ToastContainer } from 'react-toastify'
import Product from './pages/Product/Product'
import Basket from './pages/Basket'
import ProductCounter from './components/ProductCounter'
import Auth from './pages/Auth/index'
import Order from './pages/Order'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import 'rc-time-picker/assets/index.css'
import { INIT } from './gql'
import LoadingWrapper from './wrappers/LoadingWrapper'
import { getDistance } from 'geolib'
import ShopModal from './components/ShopModal'
import { Shop, State } from './reducers'
import ShopList from './pages/ShopList'
import { RouterParams } from './types/router'

type Args = {
  setMenuOpen: typeof setMenuOpen
  setBasket: typeof setBasket
  setSelectedShop: typeof setSelectedShop
  setShopList: typeof setShopList
  shopList: Shop[]
}

type UserCoordinates = {
  latitude: number
  longitude: number
}

const App: FunctionComponent<Args> = ({
  setMenuOpen,
  setBasket,
  setSelectedShop,
  setShopList,
  shopList,
}) => {
  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] < 40) {
        setMenuOpen(true)
      }
    },
    onSwipedLeft: () => {
      setMenuOpen(false)
    },
  })

  const [
    userCoordinates,
    setUserCoordinates,
  ] = useState<UserCoordinates | null>(null)
  const { data, loading } = useQuery<INITType>(INIT, {
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!loading && data) {
      const shops = data.shops
        .filter((item) => item.name)
        .map((item) => {
          const parsedCoords = item.coordinates.split(',')
          const { latitude, longitude } = userCoordinates || {}
          return {
            ...item,
            id: item.id.split('-').pop() || '',
            distance: userCoordinates
              ? getDistance(
                  { latitude: latitude || '0', longitude: longitude || '0' },
                  { latitude: parsedCoords[0], longitude: parsedCoords[1] }
                )
              : 0,
          }
        })
        .sort((a, b) => (a.distance > b.distance ? 1 : -1))
      setShopList(shops)
    }
  }, [loading, userCoordinates])

  useEffect(() => {
    if (!userCoordinates) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setUserCoordinates(pos.coords)
      })
    }
    if (localStorage.selectedShop) setSelectedShop(localStorage.selectedShop)
    if (localStorage.basket) setBasket(JSON.parse(localStorage.basket))
  }, [])

  return (
    <div ref={handlers.ref}>
      <ToastContainer position="top-center" />
      <LoadingWrapper loading={loading}>
        <ShopModal shop={userCoordinates && shopList[0]} />
        <Routes />
        <ProductCounter />
      </LoadingWrapper>
    </div>
  )
}

const Routes: FunctionComponent = () => {
  const location = useLocation()
  const { path, url, params } = useRouteMatch<RouterParams>()
  return (
    <>
      <Switch location={location}>
        <Route exact path={`${path}/auth`} component={Auth} />
        <Route path={path} exact component={Index} />
        <Route path={`${path}/product/:id`} exact component={Product} />
        <Route path={`${path}/basket`} exact component={Basket} />
        <Route path={`${path}/order`} exact component={Order} />
        <Route path={`${path}/shopList`} exact component={ShopList} />
      </Switch>
      <Route path="/">
        {({ location }) =>
          location.pathname.indexOf('/basket') === -1 &&
          location.pathname.indexOf('/auth') === -1 &&
          location.pathname.indexOf('/order') === -1 ? (
            <Link to={`/${params.company}/basket`} className="basket-link">
              <button className={'basket-btn'}>Basket</button>
            </Link>
          ) : null
        }
      </Route>
    </>
  )
}

export default connect(({ shopList }: State) => ({ shopList }), {
  setBasket,
  setMenuOpen,
  setShopList,
  setSelectedShop,
})(App)
