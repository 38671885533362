import React, { FunctionComponent } from 'react'
import { Shop } from '../../reducers'

import './index.scss'

type Args = {
    shop: Shop
}

const ShopCard: FunctionComponent<Args> = ({ shop }) => {
  return (
    <div className="shop-card-info-body">
      <div className="shop-card-info-body-main">
        <div className="shop-card-info-body-main-left">
          <p className="main-info-name">{shop.name}</p>
          <p className="main-info-hours-before-closing">Closes after 2 hours</p>
        </div>
        <div className="shop-card-info-body-main-right">
          <p className="main-info-distance">{shop.distance}</p>
        </div>
      </div>
      <div className="shop-card-info-body-secondary">
        <p className="secondary-info-address">
          {shop.formattedAddress?.split(',').map((item, idx) => (
            <span key={idx}>
              {item}
              <br />
            </span>
          ))}
        </p>
      </div>
    </div>
  )
}

export default ShopCard
