import React, { FunctionComponent } from 'react'

import { ReactComponent as HeartIcon } from './assets/favourite.svg'

import './index.scss'

const Category: FunctionComponent = () => {
  return (
    <div className={'category'}>
      <div className="category-icon-wrapper">
        <HeartIcon />
      </div>
      <p>Favourite</p>
    </div>
  )
}

export default Category
