import React, { FunctionComponent } from 'react'
import Products, { Action } from '../../components/Products'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as EditIcon } from '../../assets/edit.svg'
import Cookies from 'js-cookie';
import { connect } from 'react-redux'
import { BasketType, State } from '../../reducers'
import { setBasket, setSelectedProduct } from '../../actions'
import { getAllProducts_products } from '../Main/gql/types/getAllProducts'
import './index.scss'
import { useHistory, useRouteMatch } from 'react-router-dom';
import withWrapper from '../../components/MainWrapper';
import Button, { ButtonColors } from '../../components/Button';
import { RouterParams } from '../../types/router';

type Args = {
  data: BasketType
  setBasket: typeof setBasket
  setSelectedProduct: typeof setSelectedProduct
}

const customHandlers = {
  price: (val: number) => {
    return <span className={'product-price'}>CHF {val} (+{})</span>
  },
}

const Basket: FunctionComponent<Args> = ({
  data,
  setBasket,
  setSelectedProduct,
}) => {
  const { params } = useRouteMatch<RouterParams>()
  const history = useHistory()

  const checkAuth = () => {
    if (localStorage.signedIn === 'true') {
      history.push(`/${params.company}/order`)
    } else {
      history.push(`/${params.company}/auth`)
    }
  }
  const actions: Action[] = [
    {
      icon: <CloseIcon fill='red' />,
      onClick: (item, element) => {
        const basketClone = { ...data }
        element?.classList.add('deleted')
        if (element) {
          element.ontransitionend = () => {
            delete basketClone[parseInt(item.id)]
            setBasket(basketClone)
          }
        }
      },
    },
    {
      icon: <EditIcon />,
      onClick: (item) => setSelectedProduct(item),
    },
  ]

  const renderTotal = () => {
    return (
      <div className="total">
        <p className="total-title">Generally</p>
        <div className="total-info">
          <div className="total-info-padded">
            <table className='total-info-table'>
              <tr>
                <td>Subtotal:</td>
                <td>CHF {total}</td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td>??</td>
              </tr>
            </table>
          </div>
          <hr />
          <div className="total-info-padded">
            <table className='total-info-table'>
              <tr>
                <td className="column-bold">Grand total:</td>
                <td>CHF {total}</td>
              </tr>
            </table>
          </div>
          <div className="total-info-padded .total-info-actions">
            <Button margin='0 0 20px' width='100%' message={`Payment (CHF ${total})`} onClick={checkAuth} backgroundColor={ButtonColors.GREEN} color='white' />
            <Button width='100%' message={`Continue shopping`} border='1px solid var(--greenish-teal)' backgroundColor={ButtonColors.TRASPARENT} color='var(--greenish-teal)' />
          </div>
        </div>
      </div>
    )
  }

  const total = Object.values(data).reduce(
    (acc, item) => (acc += Math.round(item.amount * (item.price || 0)*100)/100),
    0
  )

  return (
    <div className="basket-products-wrapper">
      <h2>Basket</h2>
      <p>There are {Object.keys(data).length} products in your basket.</p>
      <Products
        actions={actions}
        fields={['name', 'price']}
        data={Object.values(data)}
        customHandlers={customHandlers}
      />
      {Object.keys(data).length > 0 && renderTotal()} 
    </div>
  )
}

export default connect(({ basket }: State) => ({ data: basket }), {
  setBasket,
  setSelectedProduct,
})(withWrapper(Basket))
