import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import ShopCard from '../../components/ShopCard'
import { Shop, State } from '../../reducers'
import withWrapper from '../../components/MainWrapper/index'

import './index.scss'
import { setSelectedShop } from '../../actions/index';

type Args = {
  shopListData: Shop[]
  setSelectedShop: typeof setSelectedShop
}

const ShopList: FunctionComponent<Args> = ({ shopListData, setSelectedShop }) => {
  const handleShopSelect = (shop: Shop) => {
    setSelectedShop(shop.id)
  }
  return (
    <div className="shop-list">
      <h2>Stores List</h2>
      <div className="shop-list-items">
        {shopListData.map((shop) => (
          <div className="shop-list-item" key={shop.id}>
            <div className="shop-list-item-info">
              <ShopCard shop={shop} key={shop.id}/>
            </div>
            <div className="shop-list-item-actions">
              <button><a href={shop.googleMapsUrl}>Open Map</a></button>
              <button onClick={() => handleShopSelect(shop)}>Choose</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default connect(
  ({ shopList }: State) => ({ shopListData: shopList }),
  { setSelectedShop }
)(withWrapper(ShopList))
