import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Header from '../Header'
import Menu from '../Menu'

const withWrapper = <P extends Object>(Component: React.ComponentType<P>) => 
    class WithWrapper extends React.Component<P> {
        render() {
            return <>
            <Header />
            <Menu />
            {/*{window.location.pathname !== '/' ? (*/}
            {/*  <button className={'back-button'}>Back</button>*/}
            {/*) : null}*/}
            <div
              style={{
                paddingTop: '70px',
                paddingBottom: '70px',
              }}
            >
                <Component {...this.props as P}/>
            </div>
          </>
        }
    }

export default withWrapper
