import React, { FunctionComponent, useEffect, useState } from 'react'
import { AiFillPhone } from 'react-icons/ai'
import './index.scss'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactCodeInput from 'react-code-input'
import Div100vh from 'react-div-100vh'
import CustomPhoneInput from '../../components/CustomPhoneInput'
import Button, { ButtonColors } from '../../components/Button'
import { useMutation } from '@apollo/client'
import { AUTHENTICATE, VERIFY } from './gql'
import {
  AUTHENTICATE as auth,
  AUTHENTICATEVariables,
} from './gql/types/AUTHENTICATE'
import { VERIFYVariables, VERIFY as VERIFY_TYPE } from './gql/types/VERIFY'
import { RouterParams } from '../../types/router'

enum Stages {
  MAIN,
  PHONE_INPUT,
  PHONE_VERIFICATION,
}

const Auth: FunctionComponent = () => {
  const [stage, setStage] = useState<Stages>(Stages.MAIN)
  const [verificationCode, setVerificationCode] = useState('')
  const { params } = useRouteMatch<RouterParams>()
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [requestId, setRequestId] = useState<string | null>(null)
  const history = useHistory()

  useEffect(() => {
    toast('Please authenticate to continue', { autoClose: 4000, type: 'info' })
  }, [])
  const [authenticate, { loading: authenticationLoading }] = useMutation<
    auth,
    AUTHENTICATEVariables
  >(AUTHENTICATE)
  const [verify, { loading: verificatioLoading }] = useMutation<
    VERIFY_TYPE,
    VERIFYVariables
  >(VERIFY)

  const handleAuth = async () => {
    if (phoneNumber) {
      const data = (
        await authenticate({ variables: { phoneNumber: phoneNumber } })
      ).data
      if (data?.login.requestId) {
        setRequestId(data?.login.requestId)
        setStage(Stages.PHONE_VERIFICATION)
      }
    }
  }

  const handleVerify = async () => {
    if (verificationCode && phoneNumber && requestId) {
      const data = (
        await verify({
          variables: {
            phoneNumber: phoneNumber,
            verificationCode: verificationCode,
            requestId: requestId,
          },
        })
      ).data
      if (data?.verify.isVerified) {
        localStorage.signedIn = true
        history.push(`/${params.company}/order`)
      }
    }
  }

  switch (stage) {
    case Stages.PHONE_VERIFICATION: {
      return (
        <Div100vh className="auth-wrapper">
          <ReactCodeInput
            type="text"
            inputMode="numeric"
            name="verification-code"
            fields={4}
            value={verificationCode}
            className='verification-input'
            onChange={(code) => setVerificationCode(code)}
          />
          <div className="auth-next-stage">
            <Button
              backgroundColor={ButtonColors.WHITE}
              message="Next"
              width="90%"
              color="rgb(44,59,65)"
              onClick={handleVerify}
            />
          </div>
        </Div100vh>
      )
    }
    case Stages.PHONE_INPUT: {
      return (
         <Div100vh className="auth-wrapper">
          <h2>Enter your phone number</h2>
          <CustomPhoneInput
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
          />
          <div className="auth-next-stage">
            <Button
              backgroundColor={ButtonColors.WHITE}
              message="Next"
              width="90%"
              color="rgb(44,59,65)"
              onClick={handleAuth}
            />
          </div>
        </Div100vh>
      )
    }
    default: {
      return (
        <Div100vh className='auth-wrapper'>
          <div className="auth-actions">
            <button
              className="auth-actions-action action-phone"
              onClick={() => setStage(Stages.PHONE_INPUT)}
            >
              <div className="action-icon">
                <AiFillPhone fill="pink" />
              </div>
              Continue with phone number
            </button>
          </div>
          <div className="auth-back-to-website-link">
            <Link to={`/${params.company}`}>Back to the website</Link>
          </div>
        </Div100vh>
      )
    }
  }
}

export default Auth
