import React, { FunctionComponent, useEffect, useState } from 'react'
import Categories from '../../components/Categories'
import SubCategories from '../../components/SubCategories'
import Products from '../../components/Products'
import { ReactComponent as LikeIcon } from '../../assets/like.svg'
import { ReactComponent as BasketIcon } from '../../assets/basket.svg'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { State } from '../../reducers'
import { connect } from 'react-redux'
import { setBasket, setSelectedProduct } from '../../actions'

import './index.scss'
import { useQuery } from '@apollo/client'
import { PRODUCTS } from './gql'
import Skeleton from 'react-loading-skeleton'
import {
  getAllProducts,
  getAllProductsVariables,
  getAllProducts_products,
} from './gql/types/getAllProducts'
import withWrapper from '../../components/MainWrapper'

type Args = {
  basket: {
    [id: number]: getAllProducts_products
  }
  searchValue: string
  setSelectedProduct: typeof setSelectedProduct
  setBasket: typeof setBasket
}

const customHandlers = {
  days: (val: number[]) => {
    return <span className={'product-days'}>Mon-Sa</span>
  },
  name: (val: string) => {
    return (
      <span>
        <b>{val}</b>
      </span>
    )
  },
  price: (val: number) => {
    return <span className={'product-price'}>CHF {val}</span>
  },
}

const Index: FunctionComponent<Args> = ({
  basket,
  setBasket,
  searchValue,
  setSelectedProduct,
}) => {
  const { loading, error, data, fetchMore } = useQuery<
    getAllProducts,
    getAllProductsVariables
  >(PRODUCTS, {
    variables: {
      search: searchValue,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [localOffset, setLocalOffset] = useState(data ? data.products.length : 0)

  useBottomScrollListener(() => {
    if (!loading) setLocalOffset(localOffset + 20)
  }, { offset: 650 })

  useEffect(() => {
    if (localOffset > 0) {
      fetchMore({
        variables: {
          search: searchValue,
          offset: localOffset,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOffset])

  console.log(localOffset, loading)

  const actions = [
    {
      icon: <LikeIcon />,
      onClick: (item: getAllProducts_products) => console.log(item),
    },
    {
      icon: <BasketIcon />,
      onClick: (item: getAllProducts_products) => setSelectedProduct(item),
    },
  ]

  return (
    <div id={'content'}>
      <Categories />
      <SubCategories />
      {(loading && !localOffset) || !data ? (
        <Skeleton
          count={20}
          height={90}
          style={{ marginBottom: '10px', borderRadius: '7px' }}
        />
      ) : (
        <Products
          customHandlers={customHandlers}
          actions={actions}
          fields={['name', 'price']}
          data={data.products}
          loadingNextPage={localOffset > 0 && loading}
        />
      )}
    </div>
  )
}

export default connect(
  ({ basket, searchValue }: State) => ({ basket, searchValue }),
  {
    setBasket,
    setSelectedProduct,
  }
)(withWrapper(Index))
