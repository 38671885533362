import React, { FunctionComponent } from 'react'
import { ReactComponent as CheckIcon } from './assets/checkbox.svg'

import './index.scss'

type Args = {
  isActive?: boolean
  title: string
  onClick: () => void
}

const CategoryOption: FunctionComponent<Args> = ({
  isActive = false,
  title,
  onClick,
}) => {
  return (
    <div
      className={isActive ? 'category-option active' : 'category-option'}
      onClick={() => onClick()}
    >
      <div className={'option-checkbox'}>{isActive && <CheckIcon />}</div>
      <span>{title}</span>
    </div>
  )
}

export default CategoryOption
