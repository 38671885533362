import React from 'react'
import { FunctionComponent } from 'react'

import './index.scss'

export enum ButtonColors {
  WHITE = 'var(--white)',
  GREEN = 'var(--greenish-teal)',
  TRASPARENT = 'transparent',
}

type Args = {
  backgroundColor: ButtonColors
  message: string
  width: string
  margin?: string
  color?: string
  onClick?: () => void
  border?: string
}

const Button: FunctionComponent<Args> = ({ backgroundColor, message, width, color, border, onClick, margin }) => {
  return (
    <button className="btn" style={{ width, background: backgroundColor, color: color || '#fff', border, margin }} onClick={onClick}>
      {message}
    </button>
  )
}

export default Button
