import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BasketType, State } from '../../reducers'
import { setBasket, setSelectedProduct } from '../../actions'

import './index.scss'
import { getAllProducts_products } from '../../pages/Main/gql/types/getAllProducts'

type Args = {
  selectedProduct: getAllProducts_products | null
  basket: BasketType
  setBasket: typeof setBasket
  setSelectedProduct: typeof setSelectedProduct
}

const ProductCounter = ({
  selectedProduct,
  basket,
  setSelectedProduct,
  setBasket,
}: Args) => {
  useEffect(() => {
    if (selectedProduct) setProductAmount(basket[parseInt(selectedProduct.id)]?.amount || 1)
  }, [basket, selectedProduct, selectedProduct?.id])

  const [productAmount, setProductAmount] = useState(
    selectedProduct ? basket[parseInt(selectedProduct?.id)]?.amount || 1 : null
  )

  if (!selectedProduct || !productAmount) return null

  

  const handleSelectionCancel = () => setSelectedProduct(null)
  const handleAddToBasket = () => {
    setBasket({
      ...basket,
      [selectedProduct.id]: { ...selectedProduct, amount: productAmount },
    })
    setSelectedProduct(null)
  }
  return (
    <div className={'product-counter'}>
      <div className="amount-selection">
        <button onClick={() => setProductAmount(productAmount - 1)}>-</button>
        <span>{productAmount}</span>
        <button onClick={() => setProductAmount(productAmount + 1)}>+</button>
      </div>
      <div className="counter-actions">
        <button onClick={handleSelectionCancel}>Cancel</button>
        <button onClick={handleAddToBasket}>Add</button>
      </div>
    </div>
  )
}

export default connect(({ basket, selectedProduct }: State) => ({ basket, selectedProduct }), {
  setBasket,
  setSelectedProduct,
})(ProductCounter)
