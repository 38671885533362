import React, { FunctionComponent } from 'react'
import Loader from 'react-loader-spinner'

import './index.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

type Args = {
  loading: boolean
}
const LoadingWrapper: FunctionComponent<Args> = ({ loading, children }) => {
  if (!loading) return <>{children}</>
  return (
    <div
      className="loading-wrapper"
    >
      <Loader
        type="BallTriangle"
        color="var(--pinky)"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  )
}

export default LoadingWrapper
