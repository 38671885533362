import React, { FunctionComponent } from 'react'
import PhoneInput from 'react-phone-input-2'
import { startsWith } from 'lodash'

import 'react-phone-input-2/lib/bootstrap.css'
import './index.scss'

type Args = {
  value: string | null
  onChange: (phoneNumber: string) => void
}

const CustomPhoneInput: FunctionComponent<Args> = ({ value, onChange }) => {
  return (
    <PhoneInput
      prefix='+'
      value={value}
      onChange={(v) => v ? onChange(`+${v}`) : onChange(v)}
      isValid={(inputNumber, _, countries: any) => {
        console.log(countries)
        return countries.some((country: any) => {
          return (
            startsWith(inputNumber, country.dialCode) ||
            startsWith(country.dialCode, inputNumber)
          )
        }) ? true : 'Invalid phone number'
      }}
      placeholder="Enter your phone number"
      onlyCountries={['de', 'fr', 'gb', 'ch', 'ua']}
      containerClass="phone-input-container"
      inputClass="phone-input"
      buttonClass="phone-input-button"
      country="de"
    />
  )
}

export default CustomPhoneInput
