import React, { FunctionComponent, useEffect, useState } from 'react'
import TimePicker from 'rc-time-picker'
import withWrapper from '../../components/MainWrapper'
import Select from 'react-select'
import { setBasket } from '../../actions'
import './index.scss'
import Button, { ButtonColors } from '../../components/Button'
import { useMutation } from '@apollo/client'
import { CREATE_ORDER } from './gql/index'
import {
  CREATE_ORDERVariables,
  CREATE_ORDER as CREATE_ORDERType,
} from './gql/types/CREATE_ORDER'
import { connect } from 'react-redux'
import { BasketType, Shop, State } from '../../reducers'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch } from 'react-router-dom'
import store from '../../store'
import { setSelectedShop } from '../../actions/index';
import { RouterParams } from '../../types/router'

type Args = {
  basket: BasketType
  setBasket: typeof setBasket
  shopList: Shop[]
  selectedShop: string | null
  setSelectedShop: typeof setSelectedShop
}

const Order: FunctionComponent<Args> = ({
  basket,
  setBasket,
  shopList,
  selectedShop,
  setSelectedShop
}) => {
  const { params } = useRouteMatch<RouterParams>()
  const [time, setTime] = useState<string>('08:00')
  const [shop, setShop] = useState<string>(
    selectedShop || (shopList[0] ? shopList[0].id : '')
  )

  useEffect(() => {
    if (selectedShop) setShop(selectedShop)
  }, [selectedShop])
  const [createOrder] = useMutation<CREATE_ORDERType, CREATE_ORDERVariables>(
    CREATE_ORDER
  )

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value)
    setSelectedShop(e.target.value)
  }

  const history = useHistory()

  const handleCreateOrder = async () => {
    try {
      const data = (
        await createOrder({
          variables: {
            items: Object.values(basket).map(({ id, amount }) => ({
              id,
              amount,
            })),
            store: shop,
          },
        })
      ).data
      if (data?.createOrder.id) {
        setBasket({})
        localStorage.basket = JSON.stringify({})
        toast('Success', {
          type: 'success',
          autoClose: 1000,
          onClose: () => history.push(`/${params.company}`),
        })
      }
    } catch (e) {
      console.log(e)
      toast('Error', { type: 'error' })
    }
  }
  useEffect(() => {
    console.log(time)
  }, [time])
  return (
    <div className="order">
      <h2>Checkout</h2>
      <div className="time">
        <p>Store pickup</p>
        {/* <Select d/> */}
        <div className="shop-select-wrapper">
          <select
            value={shop}
            onChange={handleSelectChange}
          >
            {shopList.map((shop) => (
              <option value={shop.id}>
                {shop.name} {shop.formattedAddress?.split(',').shift()}
              </option>
            ))}
          </select>
        </div>
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="order-time-input"
        />
        <Button
          message="Order"
          backgroundColor={ButtonColors.TRASPARENT}
          border="2px solid var(--greenish-teal)"
          color="var(--greenish-teal)"
          width="100%"
          onClick={handleCreateOrder}
        />
      </div>
    </div>
  )
}

export default connect(
  ({ basket, shopList, selectedShop }: State) => ({
    basket,
    shopList,
    selectedShop,
  }),
  { setBasket, setSelectedShop }
)(withWrapper(Order))
