import { gql } from "@apollo/client";

export const AUTHENTICATE = gql`
    mutation AUTHENTICATE($phoneNumber: String!) {
        login(phoneNumber: $phoneNumber) {
            requestId
        }
    }
`

export const VERIFY = gql`
    mutation VERIFY($phoneNumber: String!, $requestId: String!, $verificationCode: String!) {
        verify(phoneNumber: $phoneNumber, requestId: $requestId, verificationCode: $verificationCode) {
            isVerified
        }
    }
`