import React, { FunctionComponent, useEffect, useRef } from 'react'

import './index.scss'
import { connect } from 'react-redux'
import { State } from '../../reducers'
import {
  clearSearchValue,
  setSearchValue,
  switchSearchActive,
} from '../../actions'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { DebounceInput } from 'react-debounce-input'

type Args = {
  searchValue: string
  setSearchValue: (val: string) => void
  switchSearchActive: () => void
  clearSearchValue: () => void
}

const Search: FunctionComponent<Args> = ({
  searchValue,
  setSearchValue,
  switchSearchActive,
  clearSearchValue,
}) => {
  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault()
    clearSearchValue()
    switchSearchActive()
  }

  let inputRef: null | HTMLInputElement = null

  useEffect(() => {
    if (inputRef) {
      inputRef.focus()
    }
  }, [inputRef]) 
  return (
    <div className={'search-input-wrapper'}>
      <DebounceInput
        className={'search-input'}
        placeholder={'Search'}
        minLength={0}
        inputRef={(ref) => inputRef = ref}
        debounceTimeout={300}
        onChange={({ target: { value } }) => setSearchValue(value)}
      />
      {/* <input
        className={'search-input'}
        placeholder={'Search'}
        value={searchValue}
        onChange={({ target: { value } }) => setSearchValue(value)} */}
      {/* /> */}
      <div className={'search-input-actions'}>
        <a href="#" onClick={handleCloseClick}>
          <CloseIcon />
        </a>
      </div>
    </div>
  )
}

export default connect(({ searchValue }: State) => ({ searchValue }), {
  setSearchValue,
  clearSearchValue,
  switchSearchActive,
})(Search)
